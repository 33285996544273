@import '../../../../../assets/CSS/colors.scss';
@import '../../../../../assets/CSS/Button.scss';

$black: #000000;
$gray: #E1DFDF;
$white: #ffffff;
$bg-gray: #C6CBDB;
$thm_blue: #00B4D7;
$light_gray: #B3B0B0;
$dark_gray: #474646;
$dark_black: #201010;
$light_black: #A39F9F;

.claim-form-page {
    margin-top: 35px;

    .heading-sec {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        

        h4 {
            color: $gray_clr;
            font-size: 18px;
            line-height: 27px;
            font-weight: 700;
            margin: 0px;
            margin-bottom: 10px;
        }

        p {
            color: $black;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            margin: 0px;
        }

        .fill-primary-btn {
            background: $theme_primary_clr;
            color: $white;
            padding: 12px 24px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;

            .MuiTouchRipple-root {
                display: none;
            }
        }
    }

    .claim-details {
        flex: 1;
        margin-bottom: 20px;
        // border: 1px solid $gray; 

        .claim-item-summary {
            background-color: $white;
            // padding: 1px 35px;

            .main-title {
                text-align: center;

                h2 {
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 700;
                    color: $dark_black;
                    margin-bottom: 2px;
                }

                h4 {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    color: $dark_black;
                    margin-top: 0px;
                }
            }

            .title {
                margin-bottom: 18px;

                h5 {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 700;
                    color: $dark_gray;
                    margin-bottom: 0px;
                    margin-top: 4px;
                }

                p {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    color: $dark_gray;
                    margin-top: 3px;
                }
            }

            .details {
                display: flex;
                gap: 8px;
                min-width: 170px;

                p {
                    max-width: 335px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    color: $dark_black;
                    margin-bottom: 8px;
                }

                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: $dark_black;
                    margin-bottom: 0px;
                }

                img {
                    height: 100%;
                }
            }

            .MuiPaper-root {
                box-shadow: none;
                border: 1px solid #B2B6B7;
                border-radius:4px;

                table {
                    thead {
                        tr {
                            th {
                                // border: 1px solid #DAD7D7;
                                border-top:none;
                                // border-bottom:none;
                                padding: 8px;
                                height: 50px;
                                background-color: #f3f3f5;
                                color: #3f3e3e; 

                                // &:first-child {
                                //     height: 80px; 
                                // }

                                // &:last-child {
                                //     // border-right: none;
                                // }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                // border: 1px solid #DAD7D7;
                                padding: 8px;
                                height: 50px; 

                                &:first-child {
                                    height: 80px; 
                                }

                                // &:last-child {
                                //     // border-right: none;
                                // }

                                p{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-sec {
        background-color: $white;
        border: 1px solid $gray; 

        .claim-form-heading {
            padding: 10px 20px;
            border-bottom: 1px solid $border_clr;
            margin-bottom: 16px;

            h3 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: $title_clr;
            }
        }

        .form-fields-main-box {
            padding: 10px 20px;
            display: flex;
            // align-items: center;
            gap: 25px;

            .form-field-box {
                display: flex;
                flex-direction: column;
                width: 100%;

                .form-field-title {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: $title_clr;
                    margin: 0px;
                    margin-bottom: 12px;
                }

                .MuiInputBase-root {

                    .MuiSelect-select {
                        padding: 12px 20px;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border-color: $border_clr;
                    }

                    .MuiInputBase-input {
                        padding: 12px 20px;
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        // color: $placeholder_clr;
                    }
                }

                textarea {
                    height: 136px !important;
                    padding: 20px 25px;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    font-family: 'poppins', sans-serif;
                    border-color: $border_clr;
                }

                .error {
                    color: $error_clr;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    padding: 8px 25px;
                }

                .file-upload {
                    border: 1px dashed #ccc;
                    padding: 10px;
                    text-align: center;
                    transition: background-color 0.3s ease;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    color: $placeholder_clr;

                    &.drag-active {
                        background-color: #f0f8ff;
                        border-color: #007bff;
                    }
                }
            }
        }

        .form-field-box-checkbox {
            display:flex;
            margin-top:5px;
            
            label {
              font-size: 14px;
              color: #333;
            }
            
            input[type="checkbox"] {
              width: 16px;
              height: 16px;
              accent-color: #1976d2; 
              cursor: pointer;
            }
            
            a {
              color: #1976d2;
              text-decoration: none;
              
              &:hover {
                text-decoration: underline;
              }
            }

            .error {
                color: #FD1A1A;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                padding: 8px 25px;
            }
          }

        .submit-btn {
            button {
                margin: 30px 20px;
                padding: 12px 70px;
                background-color: $theme_primary_clr;
                color: $white;
                text-transform: none;
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                border: 0px;
            }
        }
    }
}

@media (max-width:768px) {
    .claim-form-page {
            margin-top:0px;
        .claim-item-summary {
            .MuiPaper-root {
                box-shadow: none;
                table {
                    display: flex;
                    justify-content: center;
                    thead {
                        width: 50%;
                        tr{
                            display: flex;
                            flex-direction: column;

                            th{
                                border: 1px solid #DAD7D7;

                                 &:first-child {
                                    height: 80px; 
                                }
                            }

                            
                        }
                    //   display: table-header-group; // Show headers on larger screens
                    }
              
                    tbody {
                        width: 50%;
                      tr {
                        // display: table-row; // Return to normal row display
                        display: flex;
                            flex-direction: column;
                      }
              
                      td {
                        display: table-cell;
                        padding: 8px;
                        // border-bottom: none;
                      }
                    }
                  }
                }
        }
          
        .content-sec {
            .form-fields-main-box {
                flex-wrap: wrap;

                .form-field-box {
                    width: auto;
                    flex: 1;

                    &:first-child {
                        width: 100%;
                        flex: auto;
                    }
                }
            }

            .submit-btn {
                text-align: center;

                button {
                    margin: 20px 0px 30px;
                    width: calc(100% - 20px);
                }
            }
        }
    }
}

@media (max-width:575px) {
    .claim-form-page {
        .heading-sec {
            align-items: flex-start;
            margin-bottom: 20px;

            h4 {
                font-size: 14px;
                line-height: 18px;
                margin-top: 2px;
            }

            p {
                font-size: 10px;
                line-height: 16px;
            }

            .fill-primary-btn {
                font-size: 10px;
                line-height: 16px;
                padding: 9px 14px;
                width: 100%;
                min-width: 135px;
            }
        }

        .content-sec {

            .claim-details {


                .claim-item-summary {
                    padding: 11px 9px 11px;
                }
            }

            .claim-form-heading {
                padding: 11px 9px 11px;
            }

            .form-fields-main-box {
                padding: 10px 9px;
                gap: 16px;

                .form-field-box {
                    .MuiInputBase-root {
                        .MuiInputBase-input {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    .form-field-title {
                        font-size: 12px;
                        line-height: 18px;
                        margin-bottom: 5px;
                    }

                    textarea {
                        padding: 10px 15px;
                        font-size: 12px;
                    }

                    .error {
                        padding: 5px 0px;
                        font-size: 10px;
                        line-height: 14px;
                    }

                    .file-upload {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}

@media (max-width:375px) {
    .claim-form-page {
        .content-sec {
            .form-fields-main-box {
                .form-field-box {
                    .form-field-title {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}