$thm_blue: #00B4D7;
$white: #ffffff;

.no-claims {
    text-align: center;
    padding: 20px 10px;

    img {
        margin-bottom: 17px;
        max-width: 321px;
        width: 100%;
        height: auto;
    }

    h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 7px;
        color: #444343;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 17px;
        color: #787676;
    }

    button {
        background-color: $thm_blue;
        color: #F7F5F5;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        padding: 14px 24px;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: $thm_blue;
        }

        .MuiTouchRipple-root {
            display: none;
        }
    }
}