@import "../../../../assets/CSS/colors.scss";
@import "../../../../assets/CSS/Button.scss";

.claim-products-list-page {
  margin-top: 35px;

  .heading-sec {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    h4 {
      color: $gray_clr;
      font-size: 18px;
      line-height: 27px;
      font-weight: 700;
      margin: 0px;
      margin-bottom: 10px;
    }

    p {
      color: $black;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      margin: 0px;
    }

    .fill-primary-btn {
      background: $theme_primary_clr;
      color: $white;
      padding: 12px 24px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 700;
      border: 0px;

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .content-sec {
    border: 1px solid #b2b6b7;
    border-radius: 4px;
    table.web {
      background-color: $white;

      thead {
        tr {
          th {
            // padding: 12px 18px;
            // border: 1px solid $border_clr;
            color: $gray_clr;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            background-color: #f3f3f5;
            color: #3f3e3e;

            .select-action-btn {
              .MuiSelect-select {
                padding: 0px 32px 0px 0px;
              }

              .MuiOutlinedInput-notchedOutline {
                border: none;
              }
            }
          }
        }
      }

      tbody {
        tr {
          align-items: center;
          border-bottom: 1px solid $light_gray;

          &:last-child {
            border-bottom: none;
          }
          td {
            padding: 15px 18px;
            // border: 1px solid $border_clr;
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            color: $table_data_clr;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }

            button {
              background-color: $theme_primary_clr;
              color: $white;
              padding: 7px 14px;
              font-size: 14px;
              line-height: 21px;
              font-weight: 600;
              border: 0px;
              width: 100px;

              .MuiTouchRipple-root {
                display: none;
              }
            }
          }
        }
      }
    }

    table.mobile {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .claim-products-list-page {
    margin-top: 0px;
  }
}

@media (max-width: 575px) {
  .claim-products-list-page {
    .heading-sec {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: 20px;

      h4 {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 2px;
      }

      p {
        font-size: 10px;
        line-height: 16px;
      }

      .fill-primary-btn {
        font-size: 10px;
        line-height: 16px;
        padding: 9px 14px;
        width: 100%;
        min-width: 135px;
        cursor: pointer;
      }
    }

    .content-sec {
      border: none;
      table.web {
        display: none;
      }

      table.mobile {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        tbody {
          background-color: $white;
          border: 1px solid #b2b6b7;
          border-radius: 4px;

          tr {
            td {
              border: 1px solid $border_clr;
              font-size: 14px;
              line-height: 21px;
              font-weight: 500;
              color: $gray_clr;

              &.header {
                background-color: #f3f3f5;
                color: #3f3e3e;
              }

              &.MuiTableCell-alignCenter {
                font-size: 13px;
                line-height: 18px;
                font-weight: 400;
                color: $table_data_clr;
              }

              button {
                background-color: $theme_primary_clr;
                color: $white;
                padding: 9px 30px;
                font-size: 12px;
                line-height: 15px;
                font-weight: 700;
                // border-radius: 2px;
                border: none;

                .MuiTouchRipple-root {
                  display: none;
                }
              }

              .select-action-btn {
                .MuiSelect-select {
                  padding: 0px 32px 0px 0px;
                }

                .MuiOutlinedInput-notchedOutline {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.deskPagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }

  .pagination-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    @media (max-width: 600px) {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .items-label {
      font-size: 13px;

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    .items-select {
      font-size: 13px;
      width: 100px;

      @media (max-width: 600px) {
        font-size: 12px;
        width: auto;
      }

      .MuiMenuItem-root {
        font-size: 13px;

        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
    }
  }

  .MuiPagination-root {
    margin-top: 10px;
    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
    }

    .MuiPagination-ul {
      justify-content: flex-end;

      @media (max-width: 600px) {
        justify-content: center;
      }
    }
  }
}
