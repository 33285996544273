.page-wrapper {
  background-color: #fafafa;
  // background-color: #ffffff;
}

a {
  text-decoration: none;
}

// .loader-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   /* Adjust to the desired height */
// }

.MuiContainer-root {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

//General css start
.section-space {
  padding-top: 35px;
  background-color: #fff;
}

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center left;
}

.list-unstyled {
  list-style: none;
}

.center-mode {
  display: flex;
  align-items: center;
  justify-content: center;
}

//General css end
.MuiInputBase-root {
  &:hover:not(.Mui-disabled, .Mui-error)::before {
    border-bottom: unset !important;
  }

  &::before,
  &::after {
    border-bottom: unset !important;
  }
}

.section-title {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
  background-color: #fafafa;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  span {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0px !important;
  }
}

.content-wrapper {
  position: relative;
  // border:1px solid red;
  height: 100%;
  // padding-bottom: 20px;
}

.lang-control .MuiSelect-select,
.lang-items {
  .store-selection-drop {
    display: flex;
    align-items: center;

    .flag-img {
      height: 20px;
      width: 30px;
      background-color: rgb(255, 255, 255);
      margin-right: 0px;
    }
  }
}

.top-offer-slider {
  .slide-img {
    height: 100%;
  }

  .react-multi-carousel-dot button {
    width: 30px;
    height: 9px;
    margin: 5px;
    background: #fff;
    border: 1px solid #000000;
    border-radius: 20px;
  }

  .react-multi-carousel-dot--active button {
    background: #000000 !important;
  }
}

.top-seller-grid-slider {
  ul li {
    .MuiPaper-root {
      height: 99%;
    }
  }
}

// category list slider css
.category-home-grid-slider {
  .category-box {
    display: block;
    color: #000000;
    text-align: center;

    .category-box-img {
      height: 126px;
      width: 126px;
      border-radius: 100%;
      padding: 5px;
      overflow: hidden;
      margin: 0 auto;
      margin-bottom: 10px;
      background-color: rgb(253, 235, 238);

      img {
        border-radius: 100%;
      }

      &.active {
        border: 2px solid #06b4d8;
      }
    }

    .category-box-title {
      span {
        font-size: 18px;
        font-weight: 700;
        white-space: wrap;
        color: #000;
      }
    }
  }
}

// Porduct list box
.jeb-cust-slide {
  cursor: pointer;

  .slider-img-box {
    position: relative;
    height: 260px;
    width: 100%;
  }

  .top-sell-title {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 51px;
    text-overflow: ellipsis;
  }

  .top-sell-amount {
    color: #000000;
    font-weight: 700;
    font-size: 24px;
    // line-height: 36px;
  }

  .top-actual_amount {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }

  .sell-percentage {
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #088a05;
  }

  .price-box {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .top-vat {
    color: #000000;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .color-switch-list {
    margin-bottom: 5px;

    button {
      min-width: fit-content !important;
    }

    .color-switch {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 16px;
      font-weight: 400;
      height: 15px;
      width: 15px;
      border-radius: 100%;
    }
  }

  .stars-block {
    display: flex;
    gap: 4px;

    .stars-count {
      span {
        font-weight: 600;
      }
    }
  }
}

//brand grid
.brand-grid {
  .brand-img-box {
    padding: 5px 15px;
    @extend .center-mode;
    height: 100%;
  }
}

//Details page css
.qty-textfield {
  .MuiInputBase-input {
    padding: 7px 10px;
  }
}

.mobile-carousel-container {
  ul {
    li.react-multi-carousel-item {
      padding: 15px;
    }
  }

  .right-icon-slider {
    position: absolute;
    top: 28px;
    right: 28px;
  }
}

.delivr-status-msg {
  width: 100%;
}

.wish-list-btn {
  border-radius: 5px;
  border: 1px solid #e7e5e5;
  background: #f8f4f4;
  padding: 10px;
}

.show-more-link {
  color: #3d3c3c;
  cursor: pointer;
  padding: 3px;
  font-size: 14px;
  font-weight: 500;
}

.description-text {
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-text.expanded {
  -webkit-line-clamp: unset;
}

.right-tabs {
  .tab-box {
    height: 146px;
    height: 100px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .css-8je8zh-MuiTouchRipple-root {
    display: none !important;
  }
}

.custom-img-tab {
  .tab-box {
    height: 110px;
    width: 80px;
  }
}

.popup-slider-card {
  width: 100%;
  height: 300px;
  min-height: 300px;
  padding: 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.details-center-content {
  h3 {
    color: #000;
    font-size: 25px;
    font-weight: 700;
  }

  .dtop-img {
    img {
      width: 130px;
      height: 100%;
    }
  }

  .rating-box {
    gap: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .MuiChip-root {
      color: #fff;
      font-size: 18px;
      font-weight: 500;

      .starIcon {
        margin: 5px;
        color: inherit;
      }
    }

    span.total-rating {
      color: #5b5858;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .amount-box {
    h4 {
      color: #000;
      font-size: 30px;
      font-weight: 700;

      label {
        color: #3d3d3d;
        font-weight: 400;
      }
    }

    .amount-now,
    .amount-was {
      display: flex;
      align-items: center;
      gap: 13px;
      margin-bottom: 10px;

      label {
        font-size: 16px;
      }

      .percentage-offIs {
        font-size: 14px;
        font-weight: 500;
        color: #088a05;
      }

      h4 {
        font-size: 22px;
      }
    }

    .amount-was {
      label:nth-child(2) {
        text-decoration: line-through;
      }
    }
  }

  .stock-msg {
    .in_stock {
      color: #088a05;
    }

    .out_of_stock {
      color: gray;
    }
  }

  .delivr-status-msg {
    label {
      color: #5b5858;
      font-size: 14px;
    }

    span {
      color: #1082c2;
      font-size: 14px;
      font-weight: 500;
    }
  }

  label {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }

  .qty-box {
    width: fit-content;

    label {
      color: #000;
    }
  }

  .add-cart-grp {
    display: flex;
    gap: 16px;

    button {
      font-size: 18px;
    }
  }

  .product-selector {
    // margin: 20px;

    .grinder-type-selector,
    .bag-size-selector {
      label {
        font-weight: 500;
        display: block;
        margin-bottom: 10px;

        span {
          color: #000;
        }
      }

      select {
        width: 50%;
        padding: 10px;
        border: 2.3px solid #eeeeee;
        font-size: 14px;
        cursor: pointer;
        color: #5f5e5e;

        &:focus {
          outline: none;
          border-color: #333;
        }
      }

      .bag-sizes {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;

        .bag-size-button {
          width: 50px;
          height: 50px;
          border: 1px solid #d6d6d3;
          border-radius: 10%;
          background-color: #f9f9f9;
          color: #3d3d3d;
          cursor: pointer;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;

          &.active {
            border-color: #04d5ff;
          }

          &:hover {
            border-color: #04d5ff;
          }
        }
      }
    }

    .selection-display {
      margin-top: 20px;

      p {
        font-size: 14px;
      }
    }
  }

  .color-name-block {
    span {
      color: #000;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.prod-description li {
  font-size: 14px;
  font-weight: 400;
  color: #3d3c3c;
}

.cust-tabs-list {
  margin-bottom: 15px;

  .MuiTabs-flexContainer {
    gap: 6px;

    button {
      border-radius: 10px;
      background: #f6f2f2;
    }
  }

  .MuiTabs-indicator {
    border-radius: 10px;
    border: 1px solid #1082c2;
    top: 0;
    height: 100%;
    background-color: transparent;
  }

  .MuiTabs-vertical {
    .MuiTabs-indicator {
      left: 0;
      width: 100%;
    }
  }

  &.right-img-tab {
    height: 100%;
    position: relative;
    z-index: 999;

    [role="tabpanel"] {
      width: 100%;
      background-color: #e7e5e5;
      border-radius: 10px;
      border: 1px solid #e7e5e5;
      background: #f8f4f4;
      margin: 0px 15px;

      .MuiBox-root {
        height: 100%;
        // overflow: hidden;
        @extend .center-mode;

        > div {
          display: flex;
          align-items: center;
        }
      }
    }

    .cust-tabs-view-img {
      position: sticky;
      top: 10px;

      .-magnifyimg {
        img {
          max-width: fit-content;
          max-height: 500px;
        }
      }
    }
  }
}

.details-right-content {
  .list-content {
    .MuiTypography-root {
      color: #5b5858;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;

      .MuiLink-root {
        color: #1082c2;
        text-decoration-color: #1082c2;
      }
    }
  }

  .list-content-title {
    span {
      color: #5b5858;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: unset;
    }

    p {
      color: #6e6b6b;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

.add-cart-popup {
  z-index: 999 !important;
}

.top-cart-box {
  text-align: right;

  button {
    border-radius: 1.848px;
    background: #1082c2;
    text-transform: unset;
  }
}

ul.overview-content {
  margin-top: 0;

  li {
    color: #5c5959;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}

.overview-content {
  padding: 0px;

  li {
    .pagebuilder-column-group {
      flex-wrap: wrap;
    }
  }
}

.search-result {
  position: absolute;
  top: 42px;
  background-color: white;
  padding: 20px;
  right: 0;
  left: 0;
  border-radius: 5px;
  z-index: 9999;
  height: 450px;
  color: #000;
  overflow-y: scroll;
}

.rmBtn {
  color: #00b4d7;
  font-size: 14px;
  text-decoration: underline;
}

.cart-top-wrapper {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: start;
  padding-bottom: 10px;
}

.cart-content {
  padding-top: 10px;
  display: flex;
}

.qty-label {
  color: #000;
  margin-top: 16px;
}

.qty-box {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 0;

  .qty-select {
    .MuiSelect-select {
      padding: 7px 10px;
    }

    .MuiSvgIcon-root {
      transform: unset !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }
}

img {
  max-width: 100%;
  // height: auto;
}

.rating-wrap {
  display: flex;
  align-items: center;
  gap: 6px;

  span.MuiCheckbox-root {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .progress-wrap {
    width: 250px;
  }

  .star-wrap {
    display: flex;
    align-items: center;
    width: 35px;
    justify-content: space-between;
  }

  &:nth-child(3) {
    .star-wrap {
      svg {
        color: #38ae04;
      }
    }

    .progress-wrap {
      .MuiLinearProgress-bar {
        background-color: #38ae04;
      }
    }
  }

  &:nth-child(4) {
    .star-wrap {
      svg {
        color: #82ae04;
      }
    }

    .progress-wrap {
      .MuiLinearProgress-bar {
        background-color: #82ae04;
      }
    }
  }

  &:nth-child(5) {
    .star-wrap {
      svg {
        color: #f3ac30;
      }
    }

    .progress-wrap {
      .MuiLinearProgress-bar {
        background-color: #f3ac30;
      }
    }
  }

  &:nth-child(6) {
    .star-wrap {
      svg {
        color: #f36c32;
      }
    }

    .progress-wrap {
      .MuiLinearProgress-bar {
        background-color: #f36c32;
      }
    }
  }

  &:nth-child(7) {
    .star-wrap {
      svg {
        color: #f36c32;
      }
    }

    .progress-wrap {
      .MuiLinearProgress-bar {
        background-color: #f36c32;
      }
    }
  }
}

.customer-reviews-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .customer-reviews-filter {
    display: flex;
    align-items: center;
    gap: 12px;

    .lang-filter {
      display: flex;
      gap: 12px;
      border: 1px solid;
      padding: 10px;
      border-radius: 8px;

      p {
        margin: 0;
      }
    }
  }
}

.review-start {
  border-right: 1px solid #f3f4f8;
}

.view-all-wrap {
  text-align: center;
  padding-top: 6px;
}

.customer-reviews-list {
  padding: 24px 0;
  border-bottom: 1px solid #f3f4f8;

  &:last-child {
    border-bottom: none;
  }

  .customer-reviews-list-wrap {
    display: flex;
    align-items: flex-start;

    .cust-name {
      padding: 2px 12px;

      h6 {
        margin-bottom: 0;
        font-weight: 900;
        font-size: 1rem;
        line-height: 20px;
        text-transform: capitalize;
        padding: 0;
      }

      p {
        margin: 0;
        color: #9ba0b1;
      }
    }

    .verified-user {
      display: flex;
      align-items: center;
      padding-left: 8px;
      border-left: 1px solid #f3f4f8;
      color: #3866df;

      span {
        display: contents;
      }

      svg {
        height: 18px;
      }
    }
  }

  .review-box {
    p {
      margin: 0;
    }
  }
}

.reviews-details {
  padding-left: 44px;
}

.total-ratings-count {
  margin: 0 0 4px;
}

//Quotation download css start
.quotation-download-from {
  .MuiFormLabel-root {
    font-size: 14px;
  }

  .custom-textfield .MuiInputBase-input {
    padding: 8px 10px;
    font-size: 14px;
  }
}

.offers-popup-block {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.order-time {
  font-size: 12px;
}

// Quotation download css end
@media (max-width: 1280px) {
  .customer-reviews-wrap {
    h5 {
      font-size: 18px;
    }
  }
}

@media (max-width: 767px) {
  .customer-reviews-wrap {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .customer-reviews-filter {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .customer-reviews-list {
    margin: 0 -16px;

    .customer-reviews-list-wrap {
      flex-direction: column;

      .cust-name {
        padding: 2px 0;
      }
    }
  }

  .reviews-details {
    padding-left: 15px;
  }
}

//sidebar
.sidebar-drawer {
  .MuiListItemText-root {
    .MuiTypography-root {
      // color: #fff;
      color: #000;
      background-color: #fff;
      // text-align: right;
    }
  }

  .MuiDrawer-paperAnchorRight {
    top: 65px;

    .MuiBox-root {
      &.css-or8kus {
        background-color: #fff !important;
      }
    }
  }

  .MuiDrawer-paperAnchorLeft {
    top: 65px;

    .MuiBox-root {
      &.css-or8kus {
        background-color: #fff !important;
      }
    }
  }

  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: transparent;
  }

  .MuiSvgIcon-fontSizeMedium {
    color: #000;
  }
}

//Login page layout
.toolbar-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.reg-thank-card {
  position: relative;
  padding: 30px 20px;
  text-align: center;
  background: linear-gradient(90deg, #05b8dc 0.21%, #5ee4ff 99.73%);
  color: #fff;

  .thank-reg-box {
    position: absolute;
    background-color: #fafafa;
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4.535px;
    box-shadow: 0px 2.26727px 17.00452px 0px rgba(0, 0, 0, 0.25);
    bottom: -32%;

    .go-site-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

//checkout page
.small-textfield {
  .MuiInputBase-input {
    padding: 10px 14px;
    font-size: 14px;
  }
}

.total-qty-box {
  .MuiInputBase-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: black;
  }
}

.cart-total-items {
  max-height: 480px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 20px;
}

.cart-total-items::-webkit-scrollbar {
  width: 6px;
}

.cart-total-items::-webkit-scrollbar-thumb {
  background-color: #e3e3e3;
  border-radius: 10px;
}

.form-label {
  text-align: center;
  margin-bottom: 20px;

  label {
    font-size: 23px;
    line-height: 1.235;
    color: #000;
    font-weight: 700;
  }
}

.error-msg {
  font-size: 16px;
  margin-top: 4px;
  color: #e3362e;
  font-weight: 500;
}

.form-lbl-grp {
  background-color: #f1f3f6;
  border: 0.25px solid #000;
  padding: 10px 20px;
  color: #2b2c2d;

  .MuiRadio-root {
    padding: 20px;
    width: 115px;
  }

  .MuiFormControlLabel-label {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

    span {
      border-left: 1px solid #aaa4a4;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.final-cart-details {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item-lbl {
    color: #5c5b5b;
    font-size: 14px;
    font-weight: 500;
  }

  .item-val {
    color: #3a3838;
    font-size: 14px;
    font-weight: 700;
  }
}

.ckeckout-wrapper {
  .checkout-cont-wrap {
    .MuiCardContent-root {
      padding-bottom: 10px !important;
    }

    .address-content {
      padding-left: 10px;
      display: flex;
      gap: 7px;
      flex-wrap: wrap;

      h6 {
        font-size: 16px;
        font-weight: 400;
        color: #5c5b5b;
        margin-bottom: 4px;
      }

      .selected {
        font-weight: 600;
        color: #00bfff;
      }
    }

    .address-button {
      text-transform: capitalize;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: darken(#aeb1b2, 10%);
      }
    }
  }
}

.checkout-cont-wrap {
  .MuiBox-root.css-gg4vpm {
    div {
      color: #10cdf2;
    }
  }

  .pwd_visiblity {
    position: absolute;
    top: 59%;
    right: 24px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

//sign in modal
.register-modal {
  ul.MuiList-root {
    .login-wrapper {
      .reg-content-wrap {
        .MuiContainer-maxWidthMd {
          max-width: 100%;

          .css-binzgt {
            margin-top: 0;
          }

          .css-1gzuic3-MuiGrid-root {
            margin-left: 0;
            width: 100%;
          }

          .css-1gzuic3-MuiGrid-root > .MuiGrid-item {
            padding-left: 0;
            max-width: 100%;
            flex-basis: 100%;
          }

          .css-q96ekn-MuiPaper-root-MuiCard-root {
            padding: 15px;
          }

          h6 {
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
    }
  }
}

.reg-content-wrap {
  .MuiBox-root.css-gg4vpm {
    div {
      color: #10cdf2;
    }
  }
}

.google-login-container {
  .social-button-group {
    .social_button {
      background: #4285f4;
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 2px;
      padding: 3px;
      display: flex;
      justify-content: center;
      min-height: 50px;
      text-transform: capitalize;

      .g_icon {
        background-color: #ffffff;
        width: 53px;
        height: 45px;
        border-radius: 2px 0px 0px 2px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      span {
        margin-right: auto;
      }
    }
  }
}

//Add new address
.add-address-modal {
  .MuiCardContent-root {
    height: 650px;
    overflow-y: auto;
  }

  .MuiCardContent-root::-webkit-scrollbar {
    width: 6px;
  }

  .MuiCardContent-root::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  .MuiCardContent-root::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .MuiCardContent-root::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  .MuiCardContent-root {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }

  .MuiGrid-root.MuiGrid-container {
    margin-top: 0px !important;
  }
}

//order table
.order-tbl-container {
  .MuiTableHead-root {
    .MuiTableRow-root th {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }

    .MuiTableRow-root th:last-child {
      border-right: 1px solid rgba(224, 224, 224, 1);
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root:last-child {
        border-right: 1px solid rgba(224, 224, 224, 1);
      }
    }
  }

  .css-2bmurj-MuiTableRow-root:last-child td,
  .css-2bmurj-MuiTableRow-root:last-child th {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
  }
}

.dashboard-wrapper {
  background-color: "#fff";
}

//dashborad css start
.change_pwd-modal {
  .change_pwd_strength {
    word-break: break-all;
  }
}

//dashboard css end

.filter-box {
  .filter-btn {
    width: 100%;
    border: 1px solid #e1e1e1;

    &:hover {
      text-decoration: none;
      background-color: rgb(255, 255, 255);
      box-shadow: none;
    }

    .MuiButton-endIcon {
      position: absolute;
      right: 0;
      background-color: #f8f6f6;
      border: 1px solid #dedbdb;
      width: 50px;
      height: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 -1px;
    }
  }
}

.filter-popver {
  .MuiPaper-root {
    right: 0;
    // height:300px;
  }

  .MuiPaper-root.MuiPopover-paper {
    max-height: 340px;
    max-width: 296px;
  }
}

//checkout page css start
.checkout-cont-wrap {
  .css-i087b1-MuiFormGroup-root {
    overflow: auto;
  }
}

.dash-content-wrap {
  .css-12zrijd-MuiButtonBase-root-MuiButton-root {
    color: #fff;
  }
}

// Responsive css
@media (max-width: 1399.98px) {
  .category-home-grid-slider .category-box .category-box-img {
    height: 110px;
    width: 110px;
  }
}

@media (max-width: 1200px) {
  .category-home-grid-slider .category-box .category-box-title span {
    font-size: 16px;
  }

  .checkout-cont-wrap {
    .css-4id84g {
      flex-wrap: wrap;

      .address-content {
        width: 100%;
        margin-bottom: 10px;

        .css-ojfi3k-MuiTypography-root {
          line-height: 20px;
          font-size: 17px;
        }
      }

      .css-17oy0k3-MuiButtonBase-root-MuiButton-root {
        font-size: 15px;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .checkout-cont-wrap {
    .css-4id84g {
      flex-wrap: wrap;

      .address-content {
        width: 100%;
        margin-bottom: 10px;

        .css-ojfi3k-MuiTypography-root {
          line-height: 20px;
          font-size: 17px;
        }
      }

      .css-17oy0k3-MuiButtonBase-root-MuiButton-root {
        font-size: 15px;
      }
    }

    .css-2g34zu-MuiCardContent-root:last-child {
      padding-bottom: 0;
    }

    .cart-content {
      flex-wrap: wrap;

      .css-j7qwjs {
        width: 100%;

        .css-t0my3o-MuiTypography-root {
          font-size: 15px;
          width: 100%;
        }

        .qty-box {
          .css-f0eqg5 {
            min-width: 87px;
          }
        }

        .css-qn5rft-MuiTypography-root {
          font-size: 14px;
        }
      }
    }

    .final-cart-details {
      padding-top: 15px;
    }

    button {
      font-size: 17px;
    }

    .css-i087b1-MuiFormGroup-root {
      gap: 5px;
      overflow: auto;
    }
  }

  //dashboard css start
  .dashboard-wrapper {
    .dash-content-wrap {
      //myorder-list css start
      .order-tbl-container {
        table {
          td {
            white-space: nowrap;
          }
        }
      }

      .MuiTablePagination-root.css-jtlhu6-MuiTablePagination-root {
        width: 75%;
      }

      .MuiTablePagination-actions {
        display: flex;

        button {
          width: auto;
        }
      }

      //myorder-list css end
    }
  }

  //dashboard css end
  .offers-popup-container {
    max-width: 100%;
  }
}

@media (max-width: 960px) {
  .cust-tabs-list {
    &.right-img-tab {
      [role="tabpanel"] {
        .MuiBox-root {
          > div {
            height: 350px;
            width: 350px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .cust-tabs-list {
    &.right-img-tab {
      [role="tabpanel"] {
        .MuiBox-root {
          > div {
            height: 350px;
            width: 268px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .section-title {
    span {
      font-size: 20px;
    }
  }

  .category-home-grid-slider .category-box .category-box-title span {
    font-size: 14px;
  }

  .jeb-cust-slide {
    .top-sell-title {
      font-size: 14px;
    }

    .price-box {
      gap: 4px;

      // .sell-percentage{
      //   margin-top: -12px;
      // }

      .top-sell-amount {
        font-size: 17px;
        line-height: 30px;
      }

      .top-actual_amount {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .top-vat {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .order-success-wrap {
    .css-fpy8yh {
      width: 100%;

      .css-1t09qn1-MuiCardContent-root {
        width: 100%;
      }
    }
  }

  //dashboard css start
  .dashboard-wrapper {
    .dash-content-wrap {
      //myorder-list css start
      .order-tbl-container {
        table {
          td {
            white-space: nowrap;
          }
        }
      }

      .MuiTablePagination-root.css-jtlhu6-MuiTablePagination-root {
        width: 75%;
      }

      .MuiTablePagination-actions {
        display: flex;

        button {
          width: auto;
        }
      }

      //myorder-list css end
    }
  }

  //dashboard css end
}

// .filter-box {
//     float: left;
//     width: 30%;
// }
@media (max-width: 900px) {
  .cat-mobile-menu-position {
    bottom: 0px !important;
  }
}

@media (min-width: 900px) {
  .jeb-cust-slide {
    .top-sell-amount {
      font-size: 18px;
    }

    .top-actual_amount {
      font-size: 14px;
    }
  }
}

@media (max-width: 600px) {
  ul.overview-content li video {
    max-width: 100%;
  }

  .section-title {
    span {
      font-size: 14px;
    }

    .MuiButtonBase-root {
      font-size: 12px;
      color: #000;
      padding: 2px 6px;
      margin-right: 5px;
    }
  }

  .MuiButtonBase-root {
    font-size: 10px;
    padding: 5px 5px;
  }

  .details-center-content {
    .add-cart-grp {
      button {
        font-size: 12px;
      }
    }

    h3 {
      font-size: 25px;
    }

    .amount-box {
      h4 {
        font-size: 24px;
      }
    }

    .delivr-status-msg {
      span {
        font-size: 16px;
      }
    }

    label {
      font-size: 16px;
    }
  }

  .details-right-content .list-content-title p {
    font-size: 12px;
  }

  .details-right-content .list-content-title span {
    font-size: 14px;
  }

  .details-right-content .list-content .MuiTypography-root {
    font-size: 16px;
  }

  ul.overview-content li {
    font-size: 14px;
  }

  .is-mobile-fixed {
    position: fixed;
    bottom: 60px;
    background-color: #fff;
    padding: 10px;
    width: 100% !important;
    z-index: 999;
    left: 0;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px;
  }

  .iss-mobile-fixed {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding: 10px;
    width: 100% !important;
    z-index: 999;
    left: 0;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px;
    margin-bottom: 10px;
  }

  .product-page .category-menu {
    display: none;
  }

  .details-center-content {
    .cust-tabs-list {
      &.right-img-tab {
        [role="tabpanel"] {
          .MuiBox-root {
            > div {
              height: 300px;
              width: 300px;
            }
          }
        }
      }
    }
  }

  .cart-total-items {
    padding-right: 0px;
  }

  .cart-content.mobile-cart-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      &:nth-child(1) {
        flex: 1;
        margin-bottom: 0px;
        width: 100px !important;
        height: 100px !important;
        margin-bottom: 0 !important;
      }

      &:nth-child(2) {
        flex: 2;
      }
    }
  }

  .small-textfield .MuiInputBase-input {
    padding: 8px 14px;
    font-size: 13px;
  }

  // .guest-register-form .MuiInputBase-input, .login-form .MuiInputBase-input{
  //     padding: 8px 14px;
  //     font-size: 13px;
  // }
  // .guest-register-form .MuiFormLabel-root, .login-form .MuiFormLabel-root {
  //     font-size: 14px;
  // }
}

//registrtaion css start
@media (max-width: 767px) {
  //registration css start
  .reg-content-wrap {
    .MuiContainer-root {
      padding-left: 8px !important;
      padding-right: 8px !important;

      .css-binzgt {
        margin-top: 20px;

        .css-1r0923i-MuiCardHeader-root {
          .MuiTypography-root {
            font-size: 17px;
            line-height: 27px;
          }
        }

        .css-46bh2p-MuiCardContent-root {
          padding: 10px 0px 0px;

          .css-vh7uy5-MuiTypography-root {
            font-size: 18px;
            line-height: 27px;
          }

          label {
            font-size: 14px;
            font-weight: 500;
          }

          input {
            padding: 12.5px 14px;
          }

          .pwd_strength {
            height: 24px;
            border-radius: 5px;
            background: #c4f5ff;
            color: #000;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            display: inline-block;
            line-height: 24px;
            padding: 0px 13px;
          }

          button {
            text-transform: none;
            box-shadow: unset;
          }

          .MuiGrid-item {
            font-size: 13px;
            line-height: 22px;
          }
        }
      }
    }

    .MuiBox-root.css-69i1ev {
      gap: 8px;

      button {
        font-size: 13px;
      }
    }
  }

  //registration css end

  //home page css start
  .css-idvdqs-MuiCardContent-root:last-child {
    padding-bottom: 15px;
  }

  .category-home-grid-slider {
    .category-box {
      .category-box-img {
        width: 70.894px;
        height: 70.894px;
        margin-bottom: 7px;
      }

      .category-box-title {
        line-height: 15px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  // .react-multi-carousel-item {
  //     padding-bottom: 10px;
  // }

  .jeb-cust-slide {
    .top-sell-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 23px !important;
      margin-bottom: -1px !important;
    }

    .price-box {
      gap: 10px;

      .top-sell-amount {
        // margin-bottom: 0;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .slider-img-box {
      // height: 118px;
      height: auto;
    }
  }

  //home page css end
  @media (max-width: 767px) {
    .category-menu {
      bottom: 0 !important;
    }
  }

  @media (max-width: 320px) {
    .jeb-cust-slide {
      .price-box {
        gap: 0px;

        .sell-percentage {
          margin-top: 0px;
        }
      }
    }
  }

  .filter-popver {
    .css-g3hgs1-MuiBackdrop-root-MuiModal-backdrop {
      background-color: rgba(16, 16, 16, 0.72);
    }

    .css-18orsqs-MuiButtonBase-root-MuiAccordionSummary-root {
      min-height: 22px;

      &.Mui-expanded {
        min-height: 22px !important;
        margin-bottom: 13px;
      }

      .css-o4b71y-MuiAccordionSummary-content {
        margin: 5px 0;

        .css-1ismtrt-MuiTypography-root {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }

    .MuiGrid-item {
      border-bottom: 1px solid #efefef;
      margin-bottom: 10px;

      .css-1pcnou9-MuiPaper-root-MuiAccordion-root:first-of-type {
        gap: 0;
        padding-bottom: 10px;
      }

      &:last-child {
        border-bottom: unset;
        margin-bottom: 0;
      }
    }

    .css-i74dtu-MuiAccordionDetails-root {
      padding: 8px 0px 0px;
      border: unset;

      .css-qjuj3r-MuiStack-root {
        gap: 10px 5px;

        a {
          padding: 0;
          background: #efefef;
          font-size: 11px;
          font-weight: 500;
          border-radius: 0;
          margin: 0;

          &:hover {
            border-color: #5ee4ff;
            background: #c8f6ff;
          }
        }
      }
    }

    .MuiChip-root {
      background: #efefef;
      font-size: 11px;
      font-weight: 500;
      border-radius: 0;
      padding: 0px 9px;
    }

    .MuiBox-root {
      font-size: 14px;
      font-weight: 500;

      button {
        width: 41px;
        height: 30px;
        color: #fff;
      }
    }
  }

  //filter css end

  //domestic-machine css start
  .category_list {
    .MuiGrid2-root {
      padding-bottom: 0;
    }
  }

  //domestic-machine css end

  //checkout page css start
  .MuiContainer-root {
    padding-left: 19px !important;
    padding-right: 19px !important;
  }

  .checkout-cont-wrap {
    .css-1rfck18 {
      width: 100%;
      margin-bottom: 30px;

      .css-th083e-MuiTypography-root {
        font-size: 17px;
      }
    }

    .css-binzgt {
      margin-top: 40px;
    }

    .css-480o17-MuiGrid-root > .MuiGrid-item {
      padding-left: 23px;
    }

    .MuiGrid-spacing-xs-4 {
      > .MuiGrid-item {
        padding-right: 6px;
        position: relative;
        padding-top: 14px;

        .MuiPaper-root {
          &:last-child {
            margin-bottom: 10px;
          }
        }
      }
    }

    .css-4id84g {
      flex-wrap: wrap;

      .address-content {
        width: 100%;
        margin-bottom: 10px;

        .css-ojfi3k-MuiTypography-root {
          line-height: 20px;
          font-size: 17px;
        }
      }

      .css-17oy0k3-MuiButtonBase-root-MuiButton-root {
        font-size: 15px;
      }
    }

    .css-2g34zu-MuiCardContent-root:last-child {
      padding-bottom: 0;
    }

    .css-i087b1-MuiFormGroup-root {
      gap: 5px;
      overflow: auto;

      .css-1i1maw-MuiFormControlLabel-root {
        border: unset;
        border-radius: 7px;
        justify-content: space-between;

        .css-1hokeax-MuiButtonBase-root-MuiRadio-root {
          order: 2;
        }
      }
    }

    .cart-content {
      flex-wrap: nowrap;

      .css-j7qwjs {
        width: 74%;

        .css-t0my3o-MuiTypography-root {
          font-size: 15px;
          width: 100%;
        }

        .MuiTypography-root {
          font-size: 14px;
          line-height: 20px;
        }

        .qty-box {
          .css-f0eqg5 {
            min-width: 87px;
          }
        }

        .css-qn5rft-MuiTypography-root {
          font-size: 14px;
        }
      }
    }

    .final-cart-details {
      padding-top: 15px;
    }
  }

  //checkoutpage css end

  //ordersuccess css start
  .order-success-wrap {
    .order-success-box {
      width: 100%;

      .order-card-content {
        width: 100%;
      }

      .order-thank-card {
        padding: 100px 0px;
      }

      .order-success-message-typo {
        font-size: 22px;
      }

      .order-id-typo {
        font-size: 14px;
        line-height: 22px;
      }

      .continue-shopping-card {
        a {
          width: 100%;

          button {
            width: 100%;
          }
        }
      }
    }
  }

  //ordersuccess css end

  //dashboard css start

  .dashboard-wrapper {
    > div {
      padding: 0;
    }

    .dash-content-wrap {
      //account-info css start
      .css-6uk0fl-MuiTypography-root {
        font-size: 14px;
      }

      .css-hvca0x-MuiGrid-root > .MuiGrid-item {
        padding-right: 0;
      }

      .css-15mc5mu {
        margin-bottom: 18px;
      }

      .css-1fkkker {
        padding: 4px 8px;

        .css-3rdfsn-MuiTypography-root {
          font-size: 14px;
        }
      }

      .css-jzvwfm-MuiTypography-root {
        font-size: 12px;
        margin-bottom: 16px;
        word-break: break-all;
      }

      .css-ojfi3k-MuiTypography-root {
        font-size: 12px;
        line-height: normal;
      }

      .css-1ybp0r1 {
        margin-bottom: 14px;
      }

      .css-121x6jy-MuiCardActions-root {
        padding: 8px 16px;

        .css-9l27io-MuiButtonBase-root-MuiButton-root {
          font-size: 12px;
          text-decoration: underline;
          text-align: left;
          justify-content: flex-start;
          padding: 0;
        }
      }

      .css-utgp04 {
        padding: 5px 15px;
        margin-top: 30px;
        margin-bottom: 17px;

        .css-f8bxr4-MuiTypography-root {
          font-size: 14px;
        }
      }

      //account-info css end

      //newsletetr css start
      .newsletter_subscription {
        .css-1quyq81 {
          padding: 8px 16px;

          h6 {
            font-size: 14px;
          }
        }

        .css-1ismtrt-MuiTypography-root {
          font-size: 14px;
        }

        button {
          min-width: 157px;
        }

        .css-1nmk1ju {
          margin-bottom: 8px;
        }

        .css-1djppr9 {
          margin-bottom: 14px;
          padding: 10px 16px;
        }

        .css-1r7uk83 {
          margin-bottom: 0;
        }
      }

      button {
        border-radius: 0;
        word-break: break-all;
        line-height: 17px;
        font-size: 14px;
      }

      //newsletetr css end

      //mywishlishlist css start
      .wishlist-content {
        .css-1nmk1ju {
          margin-bottom: 10px;
        }
      }

      //mywishlist css end

      //addressbook css start
      .address_book {
        .css-1nmk1ju {
          margin-bottom: 13px;
        }

        .MuiBox-root.css-1fkkker {
          margin-bottom: 5px;
        }

        .css-t0r4lf-MuiCardContent-root {
          padding: 10px 16px;

          p {
            font-size: 14px;
          }
        }

        .css-f8bxr4-MuiTypography-root {
          word-break: break-all;
        }
      }

      //addressbook css end

      //myorder-list css start
      .order-tbl-container {
        table {
          td {
            white-space: nowrap;
          }
        }
      }

      .MuiTablePagination-root.css-jtlhu6-MuiTablePagination-root {
        width: 75%;
      }

      .MuiTablePagination-actions {
        display: flex;

        button {
          width: auto;
        }
      }

      //myorder-list css end

      //edit-address_book css start
      .edit-address_book {
        label {
          font-size: 12px;
          font-weight: 500;
          color: #000;
        }

        input {
          height: 33px;
          padding: 2px 14px;
        }

        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
          border-color: #dddada;
          border-radius: 0;
        }

        .css-mhc70k-MuiGrid-root > .MuiGrid-item {
          padding-right: 0;
          padding-bottom: 0;
        }

        .MuiBox-root.css-1fkkker {
          margin-top: 20px;
          margin-bottom: 0px;
        }

        .MuiGrid-item {
          .css-19duy7y-MuiFormControl-root {
            border: unset;
          }
        }
      }

      //edit-address_book css end
    }
  }

  .change_pwd-modal {
    .css-1wnsr1i {
      width: 350px;
    }
  }

  //newsletter css end

  //dashboard css end

  //contact css start
  .contactpage {
    .titlehead {
      font-size: 14px;
      text-align: center;
      font-style: normal;
    }
  }

  //contact page css end
}

@media (max-width: 320px) {
  .reg-content-wrap {
    .MuiContainer-root {
      .css-binzgt {
        .css-1r0923i-MuiCardHeader-root {
          .MuiTypography-root {
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
  }

  .filter-popver {
    .MuiBox-root {
      width: 260px;
    }

    .MuiChip-root {
      padding: 0px 12px;
    }
  }

  .order-success-wrap {
    .css-fpy8yh {
      .css-hk42mg-MuiTypography-root {
        font-size: 18px;
      }

      .css-1yd7aoe-MuiTypography-root {
        font-size: 13px;
        line-height: 21px;
      }
    }
  }

  //dashboard css start

  //account-info css start

  .change_pwd-modal {
    .css-1wnsr1i {
      width: 320px;

      button {
        font-size: 12px;
      }
    }
  }

  //account-info css end

  //dashboard css end
}

// order view start
.dash-content-wrap {
  .order-wrapper {
    .css-1gjpycx-MuiPaper-root-MuiCard-root {
      border-radius: 0px;
      font-size: 14px;
    }

    .css-4kfzrb-MuiPaper-root-MuiCard-root {
      border-radius: 0px;
      font-size: 14px;
    }

    .css-ur2jdm-MuiContainer-root {
      .css-h4y409-MuiList-root {
        .css-1d4l5l2-MuiListItem-root {
          padding: 0px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 14px;

          &:last-child {
            span {
              font-weight: 600;
              font-size: 14px;
            }
          }

          .css-tlelie-MuiListItemText-root:nth-child(2) {
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

// order view End

@media (max-width: 767px) {
  // Start Customer Login

  .login-wrapper {
    .css-46bh2p-MuiCardContent-root {
      padding: 16px 0px !important;
    }

    .css-1r0923i-MuiCardHeader-root {
      .MuiTypography-root {
        font-size: 18px;
      }
    }

    .css-vh7uy5-MuiTypography-root {
      font-size: 18px !important;
    }

    .css-mhc70k-MuiGrid-root > .MuiGrid-item {
      // display: flex;
      flex-wrap: wrap !important;
    }

    form {
      .input-field-wrap {
        padding: 16px 0px 16px 16px !important;

        .input-field-label {
          font-size: 14px;
          font-weight: 500;
          color: #414040;
        }
      }

      .form-sub-text {
        font-size: 14px;
      }
    }
  }

  // End Customer Login

  // Start Details Page scss
  .overview-content {
    .pagebuilder-column-group {
      flex-wrap: wrap;
    }
  }

  .details-center-content h3 {
    font-size: 18px;
    color: #3a3939;
  }

  .details-center-content .amount-box h4 {
    font-size: 18px;
    color: #444242;
  }

  .cust-tabs-list {
    .MuiTabs-scroller {
      overflow-x: auto !important;
    }
  }

  .pagebuilder-column {
    figure {
      img {
        width: 100%;
      }
    }
  }

  .right-tabs {
    .tab-box {
      height: 70px;
    }
  }

  .cust-tabs-list {
    .cust-tabs-view-img {
      height: 290px;
    }
  }

  .qty-box {
    .css-wm8w2k {
      flex-wrap: wrap;
    }
  }

  .stock-msg {
    .delivr-status-msg {
      span {
        font-size: 15px;
        font-weight: 400;
      }
    }
  }

  .details-right-content {
    .rmBtn {
      color: #00b4d7;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .overview-box {
    // background-color: #F6F6F6;
    margin-bottom: 20px;
  }

  .dsc-block {
    // background-color: #F6F6F6;
    margin-bottom: 15px;
  }

  .add-cart-popup {
    .open {
      .css-osq9aj {
        width: 100%;
        max-width: 255px;
      }
    }

    .MuiCardActions-root {
      button {
        padding: 4px 16px;
        font-size: 14px;
      }
    }

    .sub-total-box {
      .MuiTypography-root {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .cart-top-wrapper {
    .css-1yw919q-MuiTypography-root {
      font-size: 14px;
    }
  }

  .sub-total-box {
    .css-mfds6y-MuiTypography-root {
      font-size: 16px;
    }
  }

  .cart-content {
    flex-wrap: wrap;

    > div:nth-child(1) {
      margin-bottom: 15px;
      width: 100%;
    }

    .MuiTypography-root {
      font-size: 14px;
      line-height: 20px;
    }
  }

  // End Details Page scss

  // Start Edit modal
  .add-address-modal {
    .css-m7r6nl-MuiGrid-root > .MuiGrid-item {
      padding: 0px;
    }

    .add-address-modal .MuiGrid-root.MuiGrid-container {
      margin-left: 0px;
    }

    .css-17ghnnh-MuiGrid-root {
      height: 100%;
    }

    .MuiGrid-grid-xs-5 {
      width: 100%;
      max-width: 100%;
    }

    .MuiGrid-spacing-xs-8 {
      margin-left: 0px;
    }

    .css-m7r6nl-MuiGrid-root {
      margin-top: 0px;
    }

    .MuiGrid-root {
      width: 100%;
    }

    .css-1r0923i-MuiCardHeader-root {
      padding: 8px 12px;

      .css-1r0923i-MuiCardHeader-root .MuiTypography-root {
        font-size: 18px;
        line-height: 13px;
      }
    }

    .css-vh7uy5-MuiTypography-root {
      font-size: 19px;
    }

    .css-46bh2p-MuiCardContent-root {
      padding: 16px 0px 0px;

      .css-1koq54t-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .css-178yklu {
      margin-top: 0;

      .css-mhc70k-MuiGrid-root > .MuiGrid-item {
        padding-right: 0;
      }
    }
  }

  // end Edit modal

  // start payment-section
  .payment-section {
    .css-m7r6nl-MuiGrid-root > .MuiGrid-item {
      padding: 0px;
    }

    .css-m7r6nl-MuiGrid-root {
      margin: 0px;
      width: 100%;

      .css-1ehb9mn-MuiGrid-root {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .css-3wbd18-MuiTypography-root {
      font-size: 18px;
    }

    .css-1r0923i-MuiCardHeader-root .MuiTypography-root {
      font-size: 18px;
    }

    .css-binzgt {
      margin-top: 30px;
    }

    .css-46bh2p-MuiCardContent-root {
      padding: 0;

      .css-1koq54t-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 14px;
        font-weight: 500;
      }

      .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        border-radius: 5px;
        background: #f6f6f6;
        height: 48px;
        padding: 0px 14px;
      }
    }
  }

  // End payment-section

  // start Edit account info
  .dash-content-wrap {
    .css-15mc5mu {
      .css-6uk0fl-MuiTypography-root {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }
    }

    .css-1fkkker {
      .css-3rdfsn-MuiTypography-root {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }
    }

    .MuiInputLabel-root {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }

    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        // margin-bottom: 15px;
        border-radius: 0px;

        .MuiInputBase-input {
          padding: 8px 14px;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }

    .MuiFormControlLabel-label {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }

    .css-mhc70k-MuiGrid-root > .MuiGrid-item {
      padding-top: 8px;
      font-size: 14px;
    }

    .css-12zrijd-MuiButtonBase-root-MuiButton-root {
      color: #fff;
    }
  }

  .dash-content-wrap {
    .order-tbl-container .MuiTableHead-root .MuiTableRow-root th {
      font-size: 14px;
      font-weight: 500;
    }

    .makeStyles-table-14 .MuiTableCell-root {
      font-size: 14px;
      font-weight: 400;
    }
  }

  // end My Product Review
  // start Order view
  .dash-content-wrap {
    .order-wrapper {
      .css-n5q157-MuiGrid-root > .MuiGrid-item {
        padding-right: 0px !important;
      }

      .css-4kfzrb-MuiPaper-root-MuiCard-root {
        border-radius: 0px;
      }

      .css-ur2jdm-MuiContainer-root {
        padding: 0px !important;
        margin: 0px;

        .css-hvca0x-MuiGrid-root > .MuiGrid-item {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }

        .MuiBox-root.css-69i1ev {
          flex-wrap: wrap !important;
        }

        .css-h4y409-MuiList-root {
          .css-1d4l5l2-MuiListItem-root {
            padding: 0px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            &:last-child {
              span {
                font-weight: 600;
                font-size: 14px;
              }
            }

            .css-tlelie-MuiListItemText-root:nth-child(2) {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  // End Order view
}

@media (max-width: 320px) {
  .cart-content {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 425px) {
  .ckeckout-wrapper {
    .checkout-cont-wrap {
      .address-content {
        padding-left: 5px;
        gap: 1px;
        flex-direction: column;
      }

      .address-button {
        font-size: 14px;
        padding: 5px 10px;
      }

      .MuiFormControlLabel-label {
        font-size: 14px;
      }

      .MuiTypography-root {
        font-size: 14px;
      }
    }
  }

  .dashboard-wrapper {
    .dash-content-wrap {
      button {
        border-radius: 0;
        word-break: break-all;
        line-height: 14px;
        font-size: 12px;
      }
    }
  }
}

//Direction rtl
body.ar {
  .directionrtl {
    direction: rtl !important;
    text-align: right;
  }

  .claim-details-page {
    .chat-box {
      .chat-footer {
        .chat-button {
          transform: rotate(180deg);
        }
      }

      .chat-body {
        .chat-message.received {
          border-radius: 15px 15px 0 15px !important;
        }

        .chat-message.sent {
          border-radius: 15px 15px 15px 0 !important;
        }
      }
    }
  }

  .claim-details-page {
    .chat-box {
      .chat-message {
        .chat-body {
          .message-bubble {
            .message-status {
              margin-left: 0px !important;
              margin-right: 4px !important;
            }
          }
        }
      }
    }
  }
  
  .MuiAccordionSummary-root {
    direction: rtl;
  }

  .MuiStack-root {
    direction: rtl;
  }

  .MuiFormHelperText-root {
    text-align: right;
  }

  .profile-box {
    .general-info-box {
      .input-grp {
        .input-box {
          p {
            text-align: right;
          }
        }
      }
    }
  }

  .return-items {
    .desktop-view {
      table {
        thead {
          tr {
            th {
              text-align: right;
            }
          }
        }
      }

      .body-cell {
        text-align: right;
      }
    }
  }

  .claim-products-list-page {
    .content-sec {
      table.web {
        thead {
          tr {
            th {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .claim-products-list-page {
    .content-sec {
      table.web {
        tbody {
          tr {
            td {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .MuiMenu-list .MuiMenuItem-root {
    justify-content: end;
  }

  .ckeckout-wrapper {
    .checkout-cont-wrap {
      .MuiFormControlLabel-root {
        margin-right: 0px !important;
      }
    }
  }

  .claim-form-page
    .content-sec
    .form-fields-main-box
    .form-field-box
    .MuiInputBase-root
    .MuiInputBase-input {
    padding: 12px 30px;
  }

  .tracking-details-main-box
    .tracking-body
    .tracking-details
    .order-stepper
    .custom-stepper
    .MuiStep-root
    .MuiStepConnector-root {
    left: calc(50% + 0px);
    right: calc(-50% + 0px);
  }

  .pwd_visiblity {
    position: absolute;
    top: 65%;
    left: 30px;
    right: unset;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .google-login-container {
    .social-button-group {
      .social_button {
        span {
          margin-left: auto;
          margin-right: unset;
        }
      }
    }

    // .filter-box .filter-input-box
    //         .MuiButton-endIcon {
    //             left: -9px;
    //             right: auto;
    //         }
    // }
  }

  .order-list-items {
    .card-items {
      .card-delivery-time-number {
        .product-return-item {
          .end-content {
            .product-more-details {
              button {
                svg {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }

  .tracking-details-main-box {
    .tracking-body {
      .tracking-summary-details {
        .tracking-invoice-summary {
          button {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .footer .footer-grid .MuiGrid-container {
    margin-right: -36px;
  }

  @media (min-width: 992px) and (max-width: 1366px) {
    .footer .footer-grid .MuiGrid-container .MuiGrid-root:nth-child(6) {
      border-right: 1px solid #5f5e5e !important;
    }

    .footer .footer-grid .MuiGrid-container .MuiGrid-root:nth-child(1) {
      border-right: 0px;
    }

    .footer .footer-grid .MuiGrid-container .MuiGrid-root:nth-child(4) {
      border-right: 1px solid #5f5e5e !important;
    }
  }

  @media (min-width: 1290px) {
    // .footer .footer-grid .MuiGrid-container{
    //   margin-right:-36px;
    // }

    .footer .footer-grid .MuiGrid-container .MuiGrid-root:nth-child(6) {
      border-right: 1px solid #5f5e5e !important;
    }

    .footer .footer-grid .MuiGrid-container .MuiGrid-root:nth-child(1) {
      border-right: 0px;
    }
  }

  @media (min-width: 1367px) {
    .footer .footer-grid .MuiGrid-container .MuiGrid-root:nth-child(6) {
      border-right: 1px solid #5f5e5e !important;
      padding-right: 16px;
    }

    .footer .footer-grid .MuiGrid-container .MuiGrid-root:nth-child(5) {
      border-right: 1px solid #5f5e5e !important;
    }
  }

  @media (max-width: 899px) {
    .Menu-welcome-box {
      padding: 31px 0px 31px 0px;
    }

    .footer .footer-grid .MuiGrid-container {
      margin-right: 0px;
    }
  }

  .Menu-main-list nav .MuiButtonBase-root.MuiListItemButton-root {
    text-align: right !important;
  }

  .Menu-main-list {
    padding: 12px 40px 31px 0px;
  }

  .Menu-welcome-box {
    padding: 31px 40px 31px 0px;
  }

  .Wishlist-main-cont {
    .Wishlist-details-list {
      .Wishlist-card-box {
        .wishlist-box {
          .img-box {
            .w-l-b-best-seller {
              left: auto;
              right: 10px;
            }

            .w-l-b-star {
              left: auto;
              right: 10px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 899px) {
    .Menu-welcome-box {
      padding: 31px 16px 1px 0px;
    }

    .Menu-main-list {
      padding: 12px 0px 31px 0px;
    }

    // .Menu-main-list .MuiButtonBase-root {
    //     flex-direction: row-reverse;
    // }

    .Menu-main-list nav .MuiButtonBase-root.MuiListItemButton-root {
      padding: 8px 16px;
    }
  }

  .center-content-wrapper {
    padding-left: 0px;
    padding-right: 8px;
  }

  .filter-box {
    &.filter-input-box {
      .filter-text {
        margin-left: auto;
      }

      .MuiButton-endIcon {
        left: 0;
        right: auto;
      }
    }
  }
}

.quantity-input-filed {
  input {
    width: 60px !important;
    padding: 3px 10px;
  }
}

// breadcrumb-data css
.breadcrumb-data {
  padding: 0px 10px;

  .MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-li {
      a {
        color: #3d3d3d;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      div:first-child {
        color: #3d3d3d;
        font-size: 16px;
      }
    }
  }
}

.filter-input-box {
  &.filter-box {
    .filter-btn {
      // max-width: 285px;
      // margin-top: 13px;
      // margin-bottom: 26px;
      padding: 10px 16px;
    }
  }
}

.view-all-brand-btn {
  text-transform: capitalize !important;
}

#panel1a-header {
  min-height: 46px;

  .MuiAccordionSummary-content {
    margin: 0px;
  }
}

.card-display.offers-card-display {
  overflow-y: auto;
  max-height: 80vh;
}

@media (min-width: 1200px) {
  .offers-popup-container {
    max-width: 100% !important;
  }
}

// details-page css start
// .dsc-block.reviews-details{
//     .customer-reviews-wrap{
//         align-items: center;
//         margin-bottom: 60px;
//         h5{
//             font-family: Poppins;
//             font-size: 25px;
//             font-weight: 700;
//             line-height: 38px;
//             margin-bottom: 0;
//         }
//         .review-btn{
//             width: 201px;
//             height: 48px;
//             background: #00B4D8;
//             font-size: 14px;
//             font-weight: 700;
//             line-height: 19px;
//             letter-spacing: 0em;
//             text-align: center;
//             color: #fff;
//            border: unset;
//            font-family: Poppins;

//         }
//     }
//     .reviews-images{
//         display: grid;
//         grid-template-columns: auto auto auto auto auto auto auto auto;
//         gap: 15px;
//         .images{
//             border-radius: 3px;
//             position: relative;
//             padding: 0;
//             img{
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//                 border-radius: 3px;
//             }
//             .image-overlay{
//                     top: 0;
//                     left: 0;
//                     width: 100%;
//                     height: 100%;
//                     font-family: Poppins;
//                     font-size: 22px;
//                     font-weight: 700;
//                     line-height: 33px;
//                     text-align: center;
//                     z-index: 1;
//                     color: #fff;

//                 &:before{
//                     content: '';
//                     position: absolute;
//                     top: 0;
//                     left: 0;
//                     width: 100%;
//                     height: 100%;
//                     background-color: #000;
//                     border-radius: 3px;
//                     opacity: 0.64;
//                     z-index: -1;
//                 }
//             }
//         }
//     }
//     .customer-reviews-list{
//         .review-box{
//             .title{
//                 font-family: Poppins;
//                 font-size: 18px;
//                 font-weight: 600;
//                 line-height: 19px;

//             }
//             .description{
//                 font-family: Poppins;
//                 font-size: 16px;
//                 font-weight: 400;
//                 line-height: 19px;
//                 color: #353535;

//             }
//         }
//     }
// }
// details-page css start
.dsc-block.reviews-details {
  .customer-reviews-wrap {
    align-items: center;
    margin-bottom: 60px;

    h5 {
      font-family: Poppins;
      font-size: 25px;
      font-weight: 700;
      line-height: 38px;
      margin-bottom: 0;
    }

    .review-btn {
      width: 201px;
      height: 48px;
      background: #00b4d8;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: #fff;
      border: unset;
      font-family: Poppins;
    }
  }

  .reviews-images {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    gap: 15px;

    .images {
      border-radius: 3px;
      position: relative;
      padding: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }

      .image-overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-family: Poppins;
        font-size: 22px;
        font-weight: 700;
        line-height: 33px;
        text-align: center;
        z-index: 1;
        color: #fff;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          border-radius: 3px;
          opacity: 0.64;
          z-index: -1;
        }
      }
    }

    &.user-reviews-images {
      display: flex;
      gap: 10px;

      .images {
        img {
          height: 92px;
          width: 100px;
        }
      }
    }
  }

  .customer-reviews-list {
    .review-box {
      .title {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 19px;
      }
    }
  }
}

.reviews-details {
  .user-review-main {
    .user-review-wrap {
      padding: 20px 0px 25px;
      border-bottom: 1px solid #eae9e9;

      .rating-star-tag {
        .user-rating-star {
          border-radius: 47px;
          font-size: 14px;
          font-family: "Poppins";
          color: #fff;
          padding: 2px 10px;
          display: inline-flex;
          align-items: center;
          gap: 3px;

          &.green {
            background-color: #439840;
          }

          &.orange {
            background-color: #fd6847;
          }

          &.red {
            background-color: #ea0c0c;
          }

          svg {
            width: 17px;
            height: 20px;
          }
        }

        .rating-tag {
          font-family: "Poppins";
          font-size: 18px;
          font-weight: 600;
          line-height: 19px;
          color: #353535;
          padding-left: 12px;
        }
      }

      .reviews-images {
        margin-bottom: 20px;
      }

      &:last-child {
        border-bottom: unset;
      }
    }

    .user-review {
      padding-top: 18px;

      .description {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #353535;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .dsc-block.reviews-details .customer-reviews-wrap h5 {
    font-size: 19px;
    line-height: normal;
  }

  .dsc-block.reviews-details .customer-reviews-wrap .review-btn {
    width: 182px;
    height: 39px;
    font-size: 12px;
  }

  .dsc-block.reviews-details .reviews-images {
    grid-template-columns: auto auto auto auto auto auto;
    gap: 6px;
  }

  .reviews-details .user-review-main .user-review {
    padding-top: 11px;
  }

  .reviews-details .user-review-main .user-review .description {
    font-size: 14px;
    line-height: 20px;
  }

  .dsc-block.reviews-details .customer-reviews-wrap {
    margin-bottom: 30px;
  }

  .dsc-block.reviews-details .reviews-images .images .image-overlay {
    font-size: 14px;
  }

  .all-reviews h6 {
    font-size: 17px;
  }

  .all-reviews p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1023px) {
  .reviews-details {
    padding: 30px 15px 0px;
  }

  .dsc-block.reviews-details .reviews-images {
    grid-template-columns: auto auto auto auto auto auto auto;
    margin: 0px 3px;
  }
}

@media only screen and (max-width: 767px) {
  .dsc-block.reviews-details .reviews-images {
    grid-template-columns: 22% 22% 22% 22%;
    margin: 0px 3px;
  }

  .dsc-block.reviews-details .customer-reviews-wrap {
    align-items: flex-start;
    gap: 7px;
  }

  .dsc-block h5 {
    font-size: 21px;
  }
}

// details page css end

// review modal css start
.reviews-modal {
  .MuiDialog-paper {
    max-width: 900px;
  }

  .review-title {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
  }

  .rating-star-tag {
    .user-rating-star {
      border-radius: 47px;
      font-size: 12px;
      font-family: "Poppins";
      color: #fff;
      padding: 5px 9px;
      display: inline-flex;
      align-items: center;
      gap: 3px;
      line-height: 14.52px;

      &.green {
        background-color: #439840;
      }

      &.orange {
        background-color: #fd6847;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-top: -2px;
      }
    }

    .rating-tag {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #353535;
      margin-left: 10px;
    }
  }

  .product-image {
    width: 80px;
    margin-right: 15px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .product-details {
    width: 80%;

    h5 {
      font-family: Poppins;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      color: #000;
      margin-bottom: 10px;
    }
  }

  .form-title {
    h5 {
      font-family: Poppins;
      font-size: 25px;
      font-weight: 800;
      line-height: 38px;
      color: #3d3d3d;
    }
  }

  .input-field-wrap {
    label {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: #353535;
      margin-bottom: 15px;
      display: block;
    }
  }
}

@media only screen and (max-width: 767px) {
  .reviews-modal .review-title {
    font-size: 18px;
    line-height: 24px;
  }

  .reviews-modal .form-title h5 {
    font-size: 18px;
    line-height: 38px;
  }

  .reviews-modal .input-field-wrap label {
    font-size: 15px;
    margin-bottom: 7px;
  }

  .reviews-modal .input-field-wrap .MuiInputBase-root {
    font-size: 15px;
  }
}

@media only screen and (max-width: 320px) {
  .reviews-modal .product-details {
    width: 100%;
    margin-top: 10px;
  }
}

body.ar {
  .domestic-machine-main-content {
    direction: rtl;
    text-align: right;

    .category-home-grid-slider {
      direction: rtl;
      text-align: right;
    }
  }

  .top-seller-grid-slider {
    .MuiCardContent-root {
      direction: rtl;
      text-align: right;
    }
  }

  .specification-tbl {
    th,
    td {
      direction: rtl;
      text-align: right;
    }
  }

  .MuiPagination-root {
    .MuiPagination-ul {
      li {
        .MuiPaginationItem-previousNext {
          rotate: 180deg;
        }
      }
    }
  }
}

//PDP page Download pdf start css
.download-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 214px;
  min-height: 74px;
  background-color: #f2f6fa;
  margin: 10px 10px;
  gap: 13px;
  padding: 10px;

  .download-svg {
    cursor: pointer;

    svg {
      path {
        fill: #a6bfd8;
      }
    }
  }

  .download-card-info {
    display: flex;
    gap: 10px;
    width: 82%;
    flex-wrap: wrap;

    p {
      width: 77%;
      word-break: break-all;
      font-size: 14px;
    }
  }
}

//PDP page Download pdf css end

//PDP page similar products css start
.similar-items-section {
  margin: 0px 10px 35px;
  background: #fff;
  padding: 60px 0px 30px;

  .section-title {
    background-color: transparent;
    margin-bottom: 40px;
  }

  .similiar-products {
    padding-bottom: 25px;

    .similar-product-card {
      margin-bottom: 10px;

      .tag {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #3d3d3d;
      }
    }

    &.jeb-cust-slide {
      .slider-img-box {
        img {
          height: 100%;
        }
      }

      .top-sell-amount {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}

.dsc-block {
  .specification-tbl {
    margin-bottom: 30px;

    .MuiTableRow-root {
      &:nth-of-type(odd) {
        background-color: #f0f4fd;
      }

      th,
      td {
        border: unset;
      }
    }
  }

  .download-title {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
  }

  h5 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #353535;
  }
}

@media (max-width: 767px) {
  .similar-items-section .similiar-products.jeb-cust-slide .top-sell-amount {
    font-size: 13px;
    line-height: 26px;
  }

  .similar-items-section .similiar-products.jeb-cust-slide .slider-img-box {
    height: 150px;
  }

  .dsc-block .download-title {
    font-size: 20px;
    line-height: 27px;
  }

  .dsc-block h5 {
    font-size: 20px;
    line-height: 27px;
  }

  .download-card {
    width: 100%;
    margin: 6px 10px;
  }
}

@media (max-width: 1023px) {
  .similar-items-section {
    padding: 30px 0px 30px;
  }

  .similar-items-section .section-title {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 27px;
  }
}

@media only screen and (min-width: 1024px) {
  .review-rating-start .reviews-gaps {
    flex-wrap: nowrap;
    gap: 20px;
  }

  .download-card {
    width: 209px;
  }
}

// review progress css
.review-rating-start {
  .rating-title {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #353535;
    padding: 0;
  }
}

.all-reviews {
  .total-title {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #353535;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #439840;
      width: 20px;
      height: 20px;
    }
  }

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #676565;
    text-align: center;
  }

  .progressbar-list {
    .total-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      width: 30px;
      justify-content: flex-end;
      gap: 5px;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    h6 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: #000000;
    }

    .MuiList-root {
      .MuiListItem-root {
        &:nth-of-type(4) {
          .MuiLinearProgress-bar {
            background-color: #fd6847;
          }

          .total-title {
            svg {
              fill: #fd6847;
            }
          }
        }

        &:last-child {
          .MuiLinearProgress-bar {
            background-color: #ea0c0c;
          }

          .total-title {
            svg {
              fill: #ea0c0c;
            }
          }
        }

        .MuiLinearProgress-root {
          width: 90%;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .progressbar-list {
    width: 50%;
  }

  .all-reviews .total-title {
    justify-content: flex-start;
  }

  .all-reviews p {
    text-align: left;
  }

  .progressbar-list .MuiListItem-root {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .progressbar-list {
    width: 100%;
  }

  .review-rating-start .rating-title {
    font-size: 20px;
    line-height: 27px;
  }

  .similar-items-section .section-title span {
    font-size: 20px;
  }

  .dsc-block.reviews-details .customer-reviews-wrap h5 {
    font-size: 20px;
  }

  .amount-now label {
    font-size: 12px;
  }

  .amount-now h4 {
    font-size: 14px !important;

    span {
      font-size: 12px !important;
    }
  }

  .qty-box.is-mobile-fixed-Is {
    .MuiBox-root {
      display: flex;
      align-items: center;

      .MuiButtonBase-root {
        &.add-to-cart-btn {
          padding: 10px 3px !important;
          font-size: 14px !important;
        }
      }
    }
  }

  .details-center-content label {
    font-size: 14px !important;
  }

  .add-to-card-modal-box {
    padding-right: 20px !important;
  }

  .slider-product-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    .reviwe-text {
      font-size: 12px !important;
    }

    .product-offer-tag {
      width: 100%;
      max-width: 80px;
      top: 11px !important;
    }
  }

  .mobile-carousel-container {
    .popup-slider-card {
      .MuiPaper-root {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

body .css-pxpt32 {
  padding-top: 0px;
}

/* Apply scrollbar styles globally */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
